.container{
    width: 22rem;
    height: 21rem;
    background-color: #0a102f;
    margin: 1rem;
    border-radius: 1vw;
}
.container:hover{
    transform: translateY(-10px);
    box-shadow: 0 10px 0 -4px #f3d588,
    0 16px 10px -3px #f3d588;
    transition: all 0.5s;
}
.container:active{
    transform: translateY(0px);
    box-shadow: 0 0px 0 0px #f3d588,
    0 0px 0px 0px #f3d588;
    transition: all 0.2s;
}
.image{
    width: 100%;
    color: #f3d588;
    justify-content: center;
}
.image h2{
    text-align: center;
    width: 90%;
    margin: auto;
    font-size: 1.2rem;
}
.icon{
    width: 100%;
    display: flex;
    justify-content: center;
}
.icon img{
    width: 2.3rem;
    margin: 1.5rem;

}
.text{
    color: #ebdfbf;
    width: 80%;
    margin: auto;
    font-size: 0.9rem;
    text-align: justify;
}