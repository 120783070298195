.container{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.carregando{
    width: 60px;
    height: 60px;
    background-color: transparent;
    display: block;
    border-radius: 50%;
    border: 12px solid #f3d588;
    border-top: 12px solid transparent;
    rotate: 15deg;

    animation: loading 1s ease-in-out infinite;
}

@keyframes loading {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}