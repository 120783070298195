/*.container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.carrossel{
    width: 95%;
    overflow: hidden;
}
.card_list{
    display: flex;
    align-items: center;
    justify-content: center;
}
*{
    box-sizing: border-box;
  }
  .arrow{
    cursor: pointer;
    position:relative;
    display:block;
    width:60px;
    height: 60px;
    border: solid 6px #999;
    border-radius: 100%;
    z-index: 1;
    transition: all .2s linear;
    &:before, &:after{
      content:"";
      position: absolute;
      width:35%;
      height: 10%;
      top:41%;
      left:55%;
      background: #999;
      z-index: 2;
      transform: translate(-50%, -50%) rotate(45deg);
      transition: all .2s linear;
    }
    &:after{
      z-index: 3;
      top:59%;
      left:55%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:hover{
      border: solid 8px #777;
      &:after, &:before{
        background: #777;
      }
    }
    &:active{
      border: solid 8px #111;
      &:after, &:before{
        background: #111;
      }
    }
  }
*/
.container{
    width: 90%;
    margin: auto;
}
@media (max-width: 600px){
  .container{
      width: 85%;
  }
}
@media (max-width: 350px){
  .container{
      width: 80%;
  }
}