.container {
    background-color: #000000;
    display: flex;
    color: #fff;
    user-select: none;
    border-top: solid 2px #f3d588;
    margin-top: 3rem;

    justify-content: center;
    align-items: center;
}
.footer_logo{
    width: 30rem;
    margin-block: 4rem 1rem;
}
.image{
    display: flex;
    align-items: center;
    justify-content: center;
}
.footer_content{
    display: flex;
    gap: 2rem;
    font-size: 1rem;
}
.footer_content h3{
    color: #f3d588;
}
.footer_content a {
    text-decoration: none;
    color: #fff;
}
.footer_content a:hover{
    text-decoration: underline;
}
.text{
    display: flex;
    align-items: center;
}
.text img{
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.4rem;
}
.text p{
    margin: 0.2rem;
}
.text a{
    margin: 0.2rem;
    color: white;
    text-decoration: none;
}
.text a:hover{
    text-decoration: underline;
}
.footer_copyright{
    text-align: center;
    margin-top: 4rem;
}
@media screen and (max-width: 1050px){
    .footer_content{
        flex-direction: column;
        width: 20rem;
    }
    .footer_info{
        display: flex;
        justify-content: center
    }
}
@media screen and (max-width: 600px){
    .footer_logo{
        width: 20rem;
    }
}